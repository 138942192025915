<template>
  <Defaultlayout :userprofile="userprofile">
    <div>
      <section class="food-detail-main shop-full-detail" v-if="getshopdetaildata != null && getshopdetaildata.status=='1' && loading == false">
        <div class="container">

          <div class="row no-gutters food-detail-wrap single-image-main">
            <div class="col-lg-7 mb-5 mb-lg-0" v-if="multiple_product_images!=null && multiple_product_images.length > 1">
              <div class="row">
                <div class="col-sm-3 col-4" v-if="multiple_product_images!=null && multiple_product_images.length > 1">
                  <div class="food-thumbnail">
                    <button :style="firstIndex ==0 ? 'display:none !important':'display:block !important'" @click.prevent="slidePrev" class="prev-arrow"><img src="../../assets/images/downarrow-dark.png"></button>
                    <hooper ref="vertical_slider" :wheelControl="false" :vertical="true" style="height: 462px" :itemsToShow="4" :itemsToSlide="3">
                      <slide  v-for="(multipleimage,index) in multiple_product_images" :key="index" :index="index">
                        <img :src="multipleimage.image_thumb" class="multiimage" style="cursor:pointer;" :style="previousIndex == index ? comobobColor: ''" :class="{'active-image': previousIndex == index}" @mouseover="hoverImage(index)">
                      </slide>
                    </hooper>
                    <button v-if="this.multiple_product_images.length>4" :style="lastIndex == multiple_product_images.length ? 'display:none !important':'display:block !important'" @click.prevent="slideNext" class="next-arrow"><img src="../../assets/images/downarrow-dark.png"></button>
                  </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-9 col-8">
                  <div class="food-detail-img food-slides" :class="multiple_product_images.length == 1 ? 'no-navigation':''">
                    <CoolLightBox 
                      :items="product_images" 
                      :index="product_image_index"
                      @close="product_image_index = null">
                    </CoolLightBox>
                      
                      <div class="images-wrapper">                        
                        <div v-if="multiple_product_images!=null">
                        </div>
                      <div
                        class="image"
                        v-for="(image, imageIndex) in product_images"
                        :key="imageIndex"
                        @click="product_image_index = imageIndex"
                        :style="{ backgroundImage: 'url(' + image + ')' }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-3 mb-lg-0">
              <div class="food-detail-img">
                <CoolLightBox 
                  :items="product_images" 
                  :index="product_image_index"
                  @close="product_image_index = null">
                </CoolLightBox>
                <div class="images-wrapper single-image-shopfulldetail">
                  <img :src="getshopdetaildata.data.store.store_logo" class="image" v-if="getshopdetaildata.data.store.store_logo!=''">
                  <avatar :fullname="getshopdetaildata.data.store.store_name.split(' ')[0]" :size="370" :radius="0" 
                  :color="avatarBackgroundColor"
                  v-if="getshopdetaildata.data.store.store_logo==''"
                  ></avatar>
                </div>
              </div>
            </div>
            <div :class="multiple_product_images!=null && multiple_product_images.length == 1 ? 'col-lg-8 food-single-description': 'col-lg-8'">
              <div class="food-detail">
                <span class="rating" v-if="(review_rating_module == 1 && (parseFloat(getshopdetaildata.data.store.avg_rating) > parseFloat(show_rating_different_color_code))) ? (getshopdetaildata.data.store.avg_rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                    <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                  </svg>
                  {{ getshopdetaildata.data.store.avg_rating }}
                </span>
                <span class="rating" v-if="(review_rating_module == 1 && (parseFloat(getshopdetaildata.data.store.avg_rating) <= parseFloat(show_rating_different_color_code))) ? (getshopdetaildata.data.store.avg_rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:alternet_bg_color , color: alternate_color_code_text}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                    <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="alternate_color_code_text"/>
                  </svg>
                  {{ getshopdetaildata.data.store.avg_rating }}
                </span>
                <span class="rating-count pl-2" v-if="(review_rating_module == 1) ? (getshopdetaildata.data.store.rating_count != 0 && show_review_rating == 1) : false
                ">
                  {{getshopdetaildata.data.store.rating_count}} Rating(s) 
                  <img
                  src="../../assets/images/svgs/ic_share.svg"
                  alt="share"
                  id="share"
                  @click="shareStore()"
                  style="cursor:pointer"
                >
                </span>
                <div class="d-flex flex-wrap justify-content-between align-items-center store-name-align">
                <h4 class="mt-3">{{getshopdetaildata.data.store.store_name}}
                  <span class="categorynameproductdetail" v-if="getshopdetaildata.data.store.rating_count == 0">
                  <img
                  src="../../assets/images/svgs/ic_share.svg"
                  alt="share"
                  id="share"
                  @click="shareStore()"
                  style="cursor:pointer"
                  >
                  </span>
                </h4>

                <div class="backshare d-flex flex-wrap align-items-center ml-auto">
                    <a href="javascript:void(0)" @click="redirectBackToShopDetail()" class="d-flex flex-wrap align-items-center">
                        <img class="back-button" src="../../assets/images/pre-slider-btn.png">
                    </a>
                </div>
                </div>

                <a href="javascript:void(0)" @click="getStoreByCategory(getshopdetaildata.data.store)" class="d-block sub-title black-font">{{ getshopdetaildata.data.store.category_name }} <span v-if="store_cuisines">({{ this.store_cuisines }})</span></a>
                <button type="button" :style="colorObj" class="btn button-style add-cart mb-sm-0 hoverEffect"
                  @click="redirectBackToShopDetail()"
                >Shop Now</button>
                <a v-if="show_business_address == 1" target="_blank" class="location d-block black-font" :href="store_map_link"> <img src="../../assets/images/StoreCustomMarkerIcon.png" alt="location-icon"> {{ this.store_address }}</a>
                <h6 class="shop-status" v-if="show_business_hours == 1">
                  <span :class="(isStoreOpen(getshopdetaildata.data.business_hours) != 'Closed Now') ? 'shop-open' : 'shop-close'">
                    {{ isStoreOpen(getshopdetaildata.data.business_hours) }}
                  </span>
                  <span v-if="isStoreOpen(getshopdetaildata.data.business_hours) != 'Closed Now'">
                    {{ getshopdetaildata.data.business_hours[0].from_time.slice(0,5) }}
                    –
                    {{ getshopdetaildata.data.business_hours[0].to_time.slice(0,5) }}
                    (Today)
                  </span>
                  <span v-if="isStoreOpen(getshopdetaildata.data.business_hours) == 'Closed Now' && getshopdetaildata.data.business_hours != null && getshopdetaildata.data.business_hours.length > 0">
                    Opening {{nextStoreWorkingDay(getshopdetaildata.data.business_hours) != ""?" on ":" today "}}{{nextStoreWorkingDay(getshopdetaildata.data.business_hours)}} at
                  <span v-if="getshopdetaildata.data.business_hours[0] != null">
                    {{ getshopdetaildata.data.business_hours[0].from_time.slice(0,5) }}
                  </span>
                  </span>
                </h6>
              </div>
            </div>
          </div>
          <div class="product-description mt-4" v-if="getshopdetaildata.data.store.store_description != ''">
            <h2 class="section-heading">About us</h2>
              <h6>
                {{filterDescription}}
                <a href="javascript:void(0);" v-if="this.getshopdetaildata.data.store.store_description.length > 1000" @click="showingfulltext = !showingfulltext">Show {{ showingfulltext ? "Less" : "More"}}</a>
              </h6>
          </div>
          
          <div class="product-description mt-4" v-if="getshopdetaildata.data.store_business_hours != null">
            <h2 class="section-heading">Business Hours</h2>
              <span > You can order from the store in the following Business Hours</span>
              <div class="business_hours_description_text" v-if="getshopdetaildata.data.store_business_hours!=null">
              <div :class="bh.indexOf('Closed') > -1 ? 'closed_store_status':''" :key="index" v-for="(bh,index) in getshopdetaildata.data.store_business_hours.business_hours">
                <h6 v-html="bh"></h6>
              </div>
              </div>
          </div>

          <div class="customer-review mt-4" v-if="getshopdetaildata.data.reviews !=null && ((review_rating_module == 1) ? (getshopdetaildata.data.store.avg_rating != null && show_review_rating == 1) : false)">
            <h2 class="section-heading">Customer Reviews</h2>
            <div class="carousel-wrap">
              <carousel
              :items="3"
              :margin="25"
              :autoWidth="false"
              :responsive="{0:{items:1,nav:true},600:{items:2,nav:true},992:{items:3,nav:true},1200:{items:4,nav:true}}"
              :navText="[initprevbutn,initnextbutn]"
              >

                <div class="item" v-for="(review,index) in getshopdetaildata.data.reviews" :key="index">
                  <div class="offer-box p-3">
                    <div class="profile-id media">
                      <img
                        :src="review.user_picture"
                        class="mr-3 rounded-circle"
                        alt="..."
                        v-if="review.user_picture!=''"
                        style="width:98px"
                      >
                      <avatar
                        v-else
                        :fullname="review.name"
                        :size="96"
                        class="mr-3 rounded-circle"
                      ></avatar>
                      <div class="media-body">
                        <h5>{{review.name}}</h5> <h6>{{timeSince(review.created_on)}}</h6>
                        <span class="rating" v-if="(review_rating_module == 1 && (parseFloat(review.rating) > parseFloat(show_rating_different_color_code))) ? (review.rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                            <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                          </svg>
                          {{ review.rating }}
                        </span>
                        <span class="rating" v-if="(review_rating_module == 1) && review.rating <=show_rating_different_color_code? (review.rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:alternet_bg_color , color: alternate_color_code_text}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                            <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="alternate_color_code_text"/>
                          </svg>
                          {{ review.rating }}
                        </span>
                        <span class="rating" v-if="(review_rating_module == 1 && show_rating == 1) ? (review.rating == null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                            <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                          </svg>
                          5.0
                        </span>
                      </div>
                    </div>
                    <div class="offer-description">
                      <h6 class="pt-3">
                        {{review.review | filterReview}}
                        <a href="javascript:void(0);" :style="textcolor" v-if="review.review.length > 100" @click="fullReview(review)">Read More</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </carousel>
            </div>            
          </div>
          <div class="add-more-items" v-if="getshopdetaildata.data.top_seller_items != null && getshopdetaildata.data.top_seller_items.length > 0">
            <h2 class="section-heading mt-4">Top Selling Items</h2>
              <product-list  :productlist="getshopdetaildata.data.top_seller_items" v-if="getshopdetaildata.status=='1'" :storeid="getshopdetaildata.data.store.store_id" :limit="getshopdetaildata.data.top_seller_items.length" page="product-detail" :storeopen="isStoreOpen(this.getshopdetaildata.data.business_hours) != 'Closed Now' || this.getshopdetaildata.data.order_settings.accept_after_close == 1"></product-list>
          </div>
          <div
            class="modal fade"
            id="shareStoreModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="shareStoreModal"
            aria-hidden="true"
          >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="shareStoreModalLabel">Share Store</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div class="networksocialmediashare text-center">
                               <ShareNetwork network="facebook" :url=dynamicFacebookUrl>
                                 <img class="socialmediashare" src="../../assets/images/svgs/facebookshare.svg" title="Facebook" />
                               </ShareNetwork>
                               <ShareNetwork network="twitter" :url=dynamicFacebookUrl>
                                 <img class="socialmediashare" src="../../assets/images/svgs/twittershare.svg" title="Twitter" />
                               </ShareNetwork>
                               <ShareNetwork network="whatsapp" :url=dynamicFacebookUrl>
                                 <img class="socialmediashare" src="../../assets/images/svgs/whatsappshare.svg" title="Whatsapp" />
                               </ShareNetwork>
                               <!-- <ShareNetwork network="skype" :url=dynamicFacebookUrl>
                                 <img class="socialmediashare" src="../../assets/images/svgs/skypeshare.svg" title="Skype" />
                               </ShareNetwork> -->
                               <ShareNetwork network="pinterest" :url=dynamicFacebookUrl>
                                 <img class="socialmediashare" src="../../assets/images/svgs/pinterestshare.svg" title="Pinterest" />
                               </ShareNetwork>
                               <ShareNetwork network="email" :url=dynamicFacebookUrl>
                                 <img class="socialmediashare" src="../../assets/images/svgs/emailshare.svg" title="Email" />
                               </ShareNetwork>
                               <!-- <ShareNetwork network="linkedin" :url=dynamicFacebookUrl>
                                 <img class="socialmediashare" src="../../assets/images/svgs/linkedinshare.svg" title="Linkedin" />
                               </ShareNetwork> -->
                               </div>
                               <div class="copytoclipboard text-center">
                               <button v-clipboard:copy="dynamicFacebookUrl" v-clipboard:success="clipboardSocialSuccessHandler" :style="colorObj" class="btn repeat-btn hoverEffect">Copy to clipboard</button>
                               </div>
                              </div>
                            </div>
                          </div>
                        </div>
          <div
            class="modal fade"
            id="ReplaceCartModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ReplaceCartModalLabel"
            aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="ReplaceCartModalLabel">Replace cart item(s)?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">{{ cart_message }}</div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="removeCart(getproductdetail.data)">Yes</button>
                </div>
              </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="QuantityAlertModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="QuantityAlertModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="QuantityAlertModalLabel">Quantity Alert</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">{{ qty_alert_message }}</div>
                  <div class="modal-footer">
                    <button v-if="is_min_qty" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button v-if="is_min_qty" type="button" :style="colorObj" class="btn btn-primary hoverEffect" @click="(remove_min_qty == true) ? removeMinQty(getproductdetail.data) : addMinQty(getproductdetail.data)">Yes</button>
                    <button v-else type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
                  </div>
                </div>
              </div>
            </div>

          <div
            class="modal fade repeat-alert-modal"
            id="RepeatAlertModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="RepeatAlertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="RepeatAlertModalLabel" v-if="repeat_addon_choice != null">
                      {{repeat_addon_choice.item_name}}
                      <p>Repeat last used choices/addons?</p>
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" v-if="repeat_addon_choice != null">
                  <h6>
                    <span v-for="(choice_addon, ind) in repeat_addon_choice.choice_addon_details" v-bind:key="ind">
                      <span>{{choice_addon}}</span><br>
                    </span>
                  </h6>
                  <h5><span v-html="priceFormatter(repeat_addon_choice.discounted_price)"></span></h5>
                </div>
                <div class="modal-footer">
                  <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemAddonChoices(getproductdetail.data)">Repeat</button>
                  <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemAddonChoices(getproductdetail.data)">Add New</button>
                </div>
              </div>
            </div>
          </div>

            <div
              class="modal fade repeat-alert-modal"
              id="RepeatComboAlertModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="RepeatComboAlertModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="RepeatComboAlertModalLabel" v-if="repeat_combo != null">
                        {{repeat_combo.item_name}}
                        <p>Repeat the combo choices?</p>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" v-if="repeat_combo != null">
                    <h6>
                      <span v-for="(combo, ind) in repeat_combo.combo_variants" v-bind:key="ind">
                        <span>{{combo.combo_variant_name}}</span><br>
                        <span class="ml-4" v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                            <span v-if="parseFloat(combo.unit_quantity) != '0'">
                              {{ Number( combo.unit_quantity ).toFixed(1) }}
                            </span>
                            <span v-if="combo.unit_name != null">
                              {{ combo.unit_name }}
                            </span>
                            <span v-if="combo.packaging_name != null">
                              {{ combo.packaging_name }}
                            </span>
                            <br>
                        </span>
                        <h6 class="w-100 mb-2 text-black" v-if="combo.choices != null">
                          <span v-for="(choice,ind) in filterComboChoices(combo.choices)" v-bind:key="ind">
                            <span v-if="(ind == 0)">{{choice.variant_choice_name}}: </span>
                            {{choice.variant_sub_choice_name}}<template v-if="ind + 1 < filterComboChoices(combo.choices).length">, </template> 
                          </span>
                        </h6>
                        <h6 class="w-100 mb-2 text-black" v-if="combo.addons != null">
                          <span v-for="(addon,ind) in filterComboAddons(combo.addons)" v-bind:key="ind">
                            <span v-if="(ind == 0)">{{addon.variant_addon_name}}: </span>
                            {{addon.variant_sub_addon_name}}<template v-if="ind + 1 < filterComboAddons(combo.addons).length">, </template> 
                          </span>
                        </h6>
                      </span>
                    </h6>
                    <br>
                    <h5><span v-html="priceFormatter(repeat_combo.discounted_price)"></span></h5>
                  </div>
                  <div class="modal-footer">
                    <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemCombo(getproductdetail.data)">Repeat</button>
                    <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemCombo(getproductdetail.data)">Add New</button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade customer-review-fulldetail"
              id="CustomerReviewModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="CustomerReviewModalLabel"
              aria-hidden="true"
              v-if="getshopdetaildata.data.reviews!=null"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal-body">
                          <div class="customer-review-item" v-if="review_data != null" :key="index">
                              <div class="offer-box">
                                <div class="profile-id media">
                                  <div class="profile-image">
                                  <img
                                    :src="review_data.user_picture"
                                    class="rounded-circle"
                                    alt="..."
                                    v-if="review_data.user_picture!=''"
                                  >
                                  <avatar
                                    v-else
                                    :fullname="review_data.name"
                                    :size="96"
                                    class="mr-3 rounded-circle"
                                  ></avatar>
                                  </div>
                                  <div class="media-body">
                                    <h5>{{review_data.name}}</h5> <h6>{{timeSince(review_data.created_on)}}</h6>
                                    <span class="rating" v-if="(review_rating_module == 1 && (parseFloat(review_data.rating) > parseFloat(show_rating_different_color_code))) ? (getshopdetaildata.data.store.avg_rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                                        <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                                      </svg>
                                      {{ review_data.rating }}
                                    </span>
                                    <span class="rating" v-if="(review_rating_module == 1) && parseFloat(review_data.rating) <= parseFloat(show_rating_different_color_code)? (review_data.rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:alternet_bg_color , color: alternate_color_code_text}">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                                        <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="alternate_color_code_text"/>
                                      </svg>
                                      {{ review_data.rating }}
                                    </span>
                                    <span class="rating" v-if="(review_rating_module == 1 && show_rating == 1) ? (review_data.rating == null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                                        <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                                      </svg>
                                      5.0
                                    </span>
                                  </div>
                                </div>
                                <div class="offer-description">
                                  <h6 class="pt-3">
                                    {{review_data.review}}
                                  </h6>
                                </div>
                              </div>
                          </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </section>
      <div class="col col-md shop-row" v-if="loading == true">
        <p class="text-center"><img src="../../assets/images/loading.gif"></p>
      </div>
      <CustomizeAlert pid="productdetail"></CustomizeAlert>
      <ComboOption pid="productdetail" @update="addToCartCombo" @closecombo="closeAddNewCombo"></ComboOption>
      <AvailableOption pid="productdetail" @update="addToCartAddonChoices"></AvailableOption>
    </div>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import ProductList from "../product/ProductList";
import Avatar from "vue-avatar-component";
import carousel from "vue-owl-carousel";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import moment from "moment";
import 'moment-timezone';
import { bus } from "../../main";
import AvailableOption from "../product/AvailableOption";
import ComboOption from "../product/ComboOption";
import CustomizeAlert from "../product/CustomizeAlert";
import helper from "../../store/helper";
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import localconfig from "../../global_settings.json"
export default {
  name: "ShopFullDetail",
  components: {
    Defaultlayout,
    "product-list": ProductList,
    Avatar,
    carousel,
    CoolLightBox,
    AvailableOption,
    ComboOption,
    CustomizeAlert,
    Hooper,
    Slide,
  },
  mixins:[helper],
  data() {
    return {
      showingfulltext: false,
      prevbutn:'<button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>',
      nextbutn:'<button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>',
      dynamicFacebookUrl : '',
      showButton : true,
      store_cuisines: "",
      show_business_hours:1,
      show_business_address:1,
      loading: true,
      currency_symbol: '₹',
      cart_data: null,
      cart_message: "",
      get_cart_detail: {
        user_id: "",
        cart_type: "",
        timezone: ""
      },
      remove_cart: {
        user_id: "",
        cart_type: ""
      },
      add_to_cart: {
        user_id: "",
        store_id: "",
        item_id: "",
        item_variants_id: "",
        quantity: "",
        cart_type: "",
        choices: "",
        addons: "",
        combo_items: "",
        add_new: 0,
      },
      product_notify: {
        user_id: "",
        item_id: ""
      },
      local_cart: [],
      cart_response: null,
      item_variants: {
        item_variants_id: '',
        quantity: '',
        is_added: '',
      },
      userprofile: null,
      getcategryid: "",
      productid: "",
      cartmessage: "",
      profile: {
        user_id: ""
      },
      getproductdetail: null,
      custprodctdetail: {
        item_id: "",
        user_id: "",
      },
      streitemdetail: {
        store_id: "",
        category_id: "",
        user_id: ""
      },
      stredetail: {
        store_id: "",
        user_id: "0",
        lat: "",
        long: "",
      },
      store_address: '',
      page_title:'',
      product_name:'',
      getshopdetaildata: null,
      getstoreid: "",
      qty_alert_message: '',
      is_min_qty: false,
      remove_min_qty: false,
      addon_choice_cart: false,
      used_addon_choice: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      used_choices_addons: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
      },
      repeat_addon_choice: null,
      combo_cart: false,
      used_combo: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      used_combo_data: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
      },
      repeat_combo: null,
      item_qty: 0,
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      shopfiltercategory: {
        category_id: "",
        category_name: "",
        type: "",
      },
      initprevbutn:
        '<button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>',
      initnextbutn:
        '<button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>',
      product_images: [],
      multiple_product_images:[],
      product_image_index: null,
      combo_item_data: {
        item_id: "",
        user_id: "",
      },
      combo_item_details: null,
      selectedComboItems: [],
      show_review_rating : 1,
      review_rating_module : 1,
      is_business_model: 0,
      previousIndex:0,
      firstIndex:0,
      lastIndex:0,
      aspectRatio:0,
      scrollTop:0,
      store_id:0,
      store_map_link:'',
      use_google_map:1,
      avatarBackgroundColor:"",
      avatarTextColor:"",
      show_price_same_font: 1,
      show_price_inclusive_tax: 1,
      show_price_inclusive_packaging:1,
      default_rating_bg_color:"",
      default_rating_color_code_text:"",
      show_rating_different_color_code: 0,
      alternet_bg_color:"",
      alternate_color_code_text:"",
      show_rating: 0,
      review_data: null,
    };
  },
  metaInfo() {
    return { 
        title: `${this.page_title != null && this.page_title!=undefined ? this.page_title.toString() : ''} | ${this.product_name !=null && this.product_name!=undefined ? this.product_name : ''}`,
        meta: [
          {
            name:'description',
            content: 'Lorem ipsum text'
          }
        ]
    }
  },
  updated(){
    if(this.product_images.length > 0){
      var image_height = window.$('.food-detail-img.food-slides').height();
      if(image_height!=undefined){
        window.$('section.hooper.is-vertical').css('height',image_height);
      }
    }
    this.avatarBackgroundColor = this.cancelReOrderButton.backgroundColor;
    this.avatarTextColor = this.cancelReOrderButton.color;
    window.$('.shop-full-detail .avatar table tbody tr td').css({ color: this.avatarTextColor});
    if((this.review_rating_module == 1) ? (this.getshopdetaildata.data.store.avg_rating != null && this.show_review_rating == 1) : false){
      window.$('.food-detail img.back-button').css({'margin-top': '-95px'});
    }
    
  },
  computed: {
    filterDescription: function(){
      if(this.showingfulltext){
        return this.getshopdetaildata.data.store.store_description.replace(/(?:\r\n|\r|\n)/g, ' ');
      }
      var shop_description = this.getshopdetaildata.data.store.store_description.replace(/(?:\r\n|\r|\n)/g, ' ');
      if(shop_description.length > 1800){
        return shop_description.slice(0,parseInt(this.getshopdetaildata.data.store.store_description.length / 5));
      } else {
        return shop_description;
      }
    }
  },
  mounted() {
    this.store_id = this.$route.query.storeid;
    bus.$on("globalsetting", data => {
      this.dynamicFacebookUrl = JSON.parse(data).website_url;
      this.dynamicFacebookUrl+= '/shopdetail?storeid=';
      this.dynamicFacebookUrl+=this.store_id
    });
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";

    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.getStoreDetails();
    this.originalColor();
    document.body.classList.add('top-navbar');
    document.body.classList.remove('top-menu-add-padding');

    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.review_rating_module = parseFloat(JSON.parse(data).review_rating_module);
        this.show_review_rating = parseFloat(JSON.parse(data).show_review_rating);
        this.show_business_hours = parseFloat(JSON.parse(data).show_store_business_hours);
        this.show_business_address = parseFloat(JSON.parse(data).show_store_address); 
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
        this.show_price_inclusive_tax = JSON.parse(data).show_price_inclusive_tax;
        this.show_price_inclusive_packaging = JSON.parse(data).show_price_inclusive_packaging;
        this.default_rating_bg_color = JSON.parse(data).default_rating_color_code_bg;
        this.default_rating_color_code_text = JSON.parse(data).default_rating_color_code_text;
        this.show_rating_different_color_code = JSON.parse(data).show_rating_different_color_code;
        this.alternet_bg_color = JSON.parse(data).alternate_color_code_bg;
        this.alternate_color_code_text = JSON.parse(data).alternate_color_code_text;
        this.show_rating = JSON.parse(data).show_rating;       
      }
    });
    this.is_business_model = localStorage.getItem("is_business_model");
    this.use_google_map = parseFloat(localStorage.getItem("use_google_map"));
  },
  filters: {
    filterReview: function(value) {
      if (value.length > 100) {
        return value.slice(0, 75);
      }
      return value;
    },
  },
  methods: {
        nextStoreWorkingDay(business_hours) {
       if(business_hours != null && business_hours.length > 0) {
         //this.timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          const currentdaymoment = moment.tz(this.getshopdetaildata.data.store.timezone);
          var new_current_day;
          var current_day = currentdaymoment.day() == 0 ? 6 : currentdaymoment.day()-1;
          switch (current_day) {
            case 0:
            new_current_day = 6;
            break;
            case 1:
            new_current_day = 0;
            break;
            case 2:
            new_current_day = 1;
            break;
            case 3:
            new_current_day = 2;
            break;
            case 4:
            new_current_day = 3;
            break;
            case 5:
            new_current_day = 4;
            break;
            case 6:
            new_current_day = 5;
          }
         //var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          const now = moment.tz(this.getshopdetaildata.data.store.timezone);
          if(parseInt(business_hours[0].days) != parseInt(new_current_day) && business_hours.length > 1)
          {
          var daysToAdd = parseInt(current_day) - parseInt(business_hours[0].days);
          if(daysToAdd < 0)
          {
            daysToAdd = parseInt(business_hours[0].days) - parseInt(current_day); 
          }
          return now.add(daysToAdd,'days').format("DD MMM");
          }
          else if(parseInt(business_hours[0].days) != parseInt(new_current_day) && business_hours.length > 0)
          {
          return now.add(parseInt(business_hours[0].days),'days').format("DD MMM");
          }
          else{
            return "";
          }
       }
    },
    clipboardSocialSuccessHandler () {
      this.$toast.success("URL copied to clipboard");
      window.$('#shareStoreModal').modal('hide');
    },
    slidePrev(){
      this.$refs.vertical_slider.slidePrev();
      this.firstIndex--;
      this.lastIndex--;
    },
    slideNext(){
      this.$refs.vertical_slider.slideNext();
      this.firstIndex++;
      var lastElement = this.multiple_product_images.slice(-1)[0];
      this.lastIndex = this.multiple_product_images.lastIndexOf(lastElement);
    },
    hoverImage(index){
      var indexImage = this.multiple_product_images[index].image;
      this.product_images = [];
      this.product_images.push(indexImage);
      this.previousIndex = index;
    },
    shareStore(){
      window.$('#shareStoreModal').modal('show');
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    getStoreByCategory(category){
      if(localStorage.getItem("is_business_model")== 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
    timeSince(date) {
      //var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : localconfig.timezone;
      var dd = '';
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        dd = moment.tz(date, 'DD-MM-YYYY HH:mm:ss', this.getshopdetaildata.data.store.timezone);
      }
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        dd = moment.tz(date, 'MM-DD-YYYY HH:mm:ss', this.getshopdetaildata.data.store.timezone);
      }
      var seconds = Math.floor(moment(new Date() - dd) / 1000);
      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " years ago";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months ago";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days ago";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours ago";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var response = this.currency_symbol + price;
        if(decimal != undefined){
          response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
        } else {
          response += "<span class='price-decimal'><small>.00</small></span>";
        }
        return response;
      } else {
        return this.currency_symbol + '0';
      }
    },
    redirectBackToShopDetail(){
      this.$router.push({ path: `shopdetail?storeid=${this.getshopdetaildata.data.store.store_id.toString()}` });
    },
    goBack(){
      this.$router.go(-1);
    },
    getStoreDetails() {
      this.stredetail.store_id = this.store_id;
      this.stredetail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.stredetail.lat = JSON.parse(localStorage.getItem("location_details")).lat;
      this.stredetail.long = JSON.parse(localStorage.getItem("location_details")).lng;

      this.$store
        .dispatch("getstrepagedetail", this.stredetail)
        .then(response => {
          if(response.status == 1){
            this.getshopdetaildata = response;
            this.store_address = this.getFilteredStoreAddress(this.getshopdetaildata.data.store);

          if (this.use_google_map == 1)
            this.store_map_link = 'https://www.google.com/maps/search/?api=1&query='+this.getshopdetaildata.data.store.latitude+','+this.getshopdetaildata.data.store.longitude;
          else
            this.store_map_link = 'https://www.google.com/maps/search/?api=1&query='+this.store_address;

            this.page_title = this.getshopdetaildata.data.store.store_name;
            if (this.isStoreOpen(this.getshopdetaildata.data.business_hours) == "Closed Now" && this.getshopdetaildata.data.order_settings.accept_after_close != 1)
            {
               this.showButton = false;
            }
            else
            {
               this.showButton = true;
            }
            this.store_address = this.getFilteredStoreAddress(this.getshopdetaildata.data.store);
            this.store_cuisines = this.getFilteredCuisines(this.getshopdetaildata.data.cuisines);            
          }
          this.loading = false;
        });
    },

    getFilteredCuisines(cuisines){
      if(cuisines){
        var tmp_cuisines = [];
        cuisines.forEach(value => {
          tmp_cuisines.push(value.cuisine_name);
        });
        var filtered_cuisines = tmp_cuisines.filter(function (el) { return el; });
        
        if(filtered_cuisines.length > 0){
          return filtered_cuisines.join(', ');
        } else {
          return "";
        }
      } else {
        return "";
      }
    },    
    isStoreOpen(business_hours){
      if (business_hours != null && business_hours.length > 0) {
          //var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          var openTime = business_hours[0].from_time;
          var closeTime = business_hours[0].to_time;
          const now = moment.tz(this.getshopdetaildata.data.store.timezone);
 
          const date = now.format("YYYY-MM-DD");
          var current_day = now.day();
          var new_current_day;

          switch (current_day) {
            case 0:
            new_current_day = 6;
            break;
            case 1:
            new_current_day = 0;
            break;
            case 2:
            new_current_day = 1;
            break;
            case 3:
            new_current_day = 2;
            break;
            case 4:
            new_current_day = 3;
            break;
            case 5:
            new_current_day = 4;
            break;
            case 6:
            new_current_day = 5;
          }
          if(new_current_day != business_hours[0].days){
            return "Closed Now";
          }

          let check;
          const storeOpenTime = moment.tz(date + ' ' + openTime, "YYYY-MM-DD h:mmA", this.getshopdetaildata.data.store.timezone);
          const storeCloseTime = moment.tz(date + ' ' + closeTime, "YYYY-MM-DD h:mmA", this.getshopdetaildata.data.store.timezone);

          if (storeCloseTime.isBefore(storeOpenTime)) {
            check = now.isAfter(storeOpenTime) || now.isBefore(storeCloseTime);
          } else {
            check = now.isBetween(storeOpenTime, storeCloseTime, null, '[)');
          }
          return check ? "Open Now" : "Closed Now";
      } else {
        return "Closed Now";
      }
    },
    getFilteredStoreAddress(store){
      var tmp_store_address = [];
      tmp_store_address.push(store.store_address, store.store_address2, store.area_name, store.city_name, store.zipcode);
      var filtered_store_address = tmp_store_address.filter(function (el) { return el; });
      
      if(filtered_store_address.length > 0){
        return filtered_store_address.join(', ');
      } else {
        return "";
      }
    },
    getProductDetail() {
      this.custprodctdetail.item_id = this.productid;
      this.custprodctdetail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store
        .dispatch("getproductdetail", this.custprodctdetail)
        .then(response => {
          this.getproductdetail = JSON.parse(JSON.stringify(response));
          this.product_name = this.getproductdetail.data.item_name;
          this.product_images.push(this.getproductdetail.data.item_image);
          this.multiple_product_images = this.getproductdetail.data.item_images;
          this.item_variants.item_variants_id = this.getproductdetail.data.item_variants[0].item_variants_id;
          this.item_variants.quantity = (this.getproductdetail.data.item_variants[0].quantity != null) ? this.getproductdetail.data.item_variants[0].quantity : 1;
          this.item_variants.is_added = this.getproductdetail.data.item_variants[0].is_added;
          
          
          if(localStorage.getItem("user") == null && localStorage.getItem("user_cart") != null) {
            var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];

            bus.$emit("getcartlength", user_cart.length);

            if(this.getproductdetail != null && user_cart.length > 0) {
              user_cart.forEach(e => {
                let item_variant_index = this.getproductdetail.data.item_variants.findIndex(
                    o => o.item_variants_id == e.item_variants_id
                );

                if (item_variant_index > -1){
                  this.getproductdetail.data.item_variants[item_variant_index].is_added = 1;
                  this.getproductdetail.data.item_variants[item_variant_index].quantity = e.quantity;
                }
              });
            }
          }

          this.loading = false;
          this.getcategryid = this.getproductdetail.data.category_id;
          this.getstoreid = this.getproductdetail.data.store_id;
          this.getStoreDetails();
          
        }).catch(error => {
          console.log(error);
        })
    },
    getImageSize(url,callback){
      var img = new Image();
      img.src = url;
      img.onload = function() { callback(this.width, this.height) };
    },
    getCartCount() {
      this.get_cart_detail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.get_cart_detail.cart_type = 1;
      this.get_cart_detail.timezone = "+6";
      this.$store
        .dispatch("getcartdetail", this.get_cart_detail)
        .then(response => {
          var getcartlength = 0;
          this.getcartdata = JSON.parse(JSON.stringify(response));
          if(this.getcartdata.status == 1){ 
            getcartlength = this.getcartdata.data.cart_details.quantity;
          }
          bus.$emit("getcartlength", getcartlength);
        });
    },
    isItemAdded(item){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
        if (
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).is_added == 1 
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getItemQuantity(item){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
        if(item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ).quantity != null){
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).quantity;
        } else {
          return 1;  
        }
      } else {
        return 1;
      }
    },
    getItemVarintId(item) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
        bus.$emit(
          "itemvariantid",
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).item_variants_id
        );
        return item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ).item_variants_id;
      } else {
        return false;
      }
    },
    getItemPrice: function(item) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
        if (
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).discounted_price ==
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).item_price
        ) {
          return '0';
        } else {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).item_price;
        }
      }
    },
    getItemDiscountedPrice: function(item) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
        if (
          parseFloat(item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).discounted_price) <
          parseFloat(item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).item_price)
        ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).discounted_price;
        } else {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).item_price;
        }
      }
    },
    addQuantity(item) {
      var qty = parseInt(window.$("#quantityid" + item.item_id).val());
      if(item.item_variants[0].max_qty <= qty && qty != 0 && item.item_variants[0].max_qty != 0){
        this.$toast.error("The maximum quantity for item is " + item.item_variants[0].max_qty);
        return false;
      }
      qty = qty + 1;
      window.$("#quantityid" + item.item_id).val(parseInt(qty));
      this.item_qty = parseInt(qty);
      this.addToCart(item);
      return false;
    },
    removeQuantity(item) {
      var qty = parseInt(window.$("#quantityid" + item.item_id).val());
      if(item.item_variants[0].min_qty > qty && qty != 0 & item.item_variants[0].min_qty != 0){
        this.$toast.error("The minimum quantity for item is " + item.item_variants[0].min_qty);
        return false;
      }
      if(item.item_variants[0] != undefined)
      {
        if (item.item_variants[0].min_qty >= qty)
          qty=0;
        else
          qty = qty -1;        
      }
      else{
          qty = qty -1;
      }
      if(qty != 0 && (this.checkItemAddonChoice(item) != null) || item.is_combo == 1){
        window.$('#CustomizeAlertModalproductdetail').modal("show");
        return false;
      } 
      window.$("#quantityid" + item.item_id).val(parseInt(qty));
      this.item_qty = parseInt(qty);
      this.addToCart(item);
      return false;
    },
    isStoreExistsInLocalCart(user_cart, cart_details){
      if(user_cart.length > 0){
        const i = user_cart.findIndex(o => o.store_id === cart_details.store_id);
        if (i > -1) return true;
        else return false;
      } else {
        return true;
      }
    },
    isItemExistsInLocalCart(user_cart, cart_details){
      const i = user_cart.findIndex(o => o.item_id === cart_details.item_id && o.item_variants_id === cart_details.item_variants_id);
      if (i > -1) {
        user_cart[i] = cart_details;
        if(cart_details.quantity == 0){
          user_cart.splice(i, 1);
        }
      } else { 
        user_cart.push(cart_details);
      }
      return user_cart;
    },
    addToLocalCart(item){
      this.add_to_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.add_to_cart.store_id = item.store_id;
      this.add_to_cart.item_id = item.item_id;
      this.add_to_cart.item_variants_id = window.$("#itemvarintid" + item.item_id).val();
      this.add_to_cart.quantity = window.$("#quantityid" + item.item_id).val();
      this.add_to_cart.cart_type = 1;

      var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];
      
      if(localStorage.getItem("user_cart") != null) {

        if(!this.isStoreExistsInLocalCart(user_cart, this.add_to_cart)) {
            this.cart_message = "Your cart contains items from different shop. Do you want to discard the selection and add items from current store?";
            window.$("#ReplaceCartModal").modal("show");
            return false;
        }
        
        var new_user_cart = this.isItemExistsInLocalCart(user_cart, this.add_to_cart);
        localStorage.setItem('user_cart', JSON.stringify(new_user_cart));
        this.$toast.success("Cart details updated successfully");
      } else {
        user_cart.push(this.add_to_cart);
        localStorage.setItem('user_cart', JSON.stringify(user_cart));
        this.$toast.success("Cart details updated successfully");
      }

      if (
        this.getproductdetail.data.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
        let item_variant_index = item.item_variants.findIndex(
            o => o.item_variants_id == this.item_variants.item_variants_id
        );
        if(this.add_to_cart.quantity != 0){
          this.getproductdetail.data.item_variants[item_variant_index].is_added = 1;
          this.getproductdetail.data.item_variants[item_variant_index].quantity = this.add_to_cart.quantity;
        } else {
          this.getproductdetail.data.item_variants[item_variant_index].is_added = 0;
          this.getproductdetail.data.item_variants[item_variant_index].quantity = 1;
        }
      }

      bus.$emit("getcartlength", this.totalLocalCartQuantity(user_cart));
    },
    removeLocalCart() {
      var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];

      if(this.getproductdetail.data != null && user_cart.length > 0) {

        user_cart.forEach(e => {

          let item_variant_index = this.getproductdetail.data.item_variants.findIndex(
              o => o.item_variants_id == e.item_variants_id
          );

          if (item_variant_index > -1){
            this.getproductdetail.data.item_variants[item_variant_index].is_added = 0;
            this.getproductdetail.data.item_variants[item_variant_index].quantity = 1;
          }

        });
      }

      this.local_cart = [];
      localStorage.removeItem("user_cart");
      bus.$emit("getcartlength", this.local_cart.length);
    },
    totalLocalCartQuantity(user_cart) {
      var total_qty = 0;
      user_cart.forEach(e => {
        total_qty+= parseFloat(e.quantity);
      });
      return Number(total_qty).toString();
    },
    getItemMinQty(item){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).min_qty;
      } else {
        return null;
      }
    },
    getItemMaxQty(item){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).max_qty;
      } else {
        return null;
      }
    },
    addMinQty(item) {
      var min_quantity = this.getItemMinQty(item);
      window.$("#quantityid" + item.item_id).val(parseInt(min_quantity));
      this.addToCart(item);
      window.$('#QuantityAlertModal').modal("hide");
    },
    removeMinQty(item) {
      window.$("#quantityid" + item.item_id).val(parseInt(0));
      this.addToCart(item);
      window.$('#QuantityAlertModal').modal("hide");
    },
    async addToCart(item) {
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
        return false;
      }
      
      this.add_to_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.add_to_cart.store_id = item.store_id;
      this.add_to_cart.item_id = item.item_id;
      this.add_to_cart.item_variants_id = window.$("#itemvarintid" + item.item_id).val();
      this.add_to_cart.quantity = (this.item_qty == 0) ? window.$("#quantityid" + item.item_id).val() : this.item_qty;
      this.add_to_cart.cart_type = 1;
      this.remove_min_qty = false;

      if(this.getItemMinQty(item) != null){
        if(this.getItemMinQty(item) != 0 && this.getItemMinQty(item) > this.add_to_cart.quantity && this.add_to_cart.quantity != 0 && this.isItemAdded(item) == false){
          this.is_min_qty = true;
          this.qty_alert_message = "The minimum quantity for item is " + this.getItemMinQty(item) + ", Do you want to proceed?"
          window.$('#QuantityAlertModal').modal("show");
          return false;
        } else if(this.getItemMinQty(item) != 0 && this.getItemMinQty(item) > this.add_to_cart.quantity && this.add_to_cart.quantity != 0 && this.isItemAdded(item) == true) {
          this.is_min_qty = true;
          this.remove_min_qty = true;
          this.qty_alert_message = "The minimum quantity for item is " + this.getItemMinQty(item) + ", Do you wish to remove?"
          window.$('#QuantityAlertModal').modal("show");
          return false;
        }
      }

      if(this.getItemMaxQty(item) != null){
        if(this.getItemMaxQty(item) != 0 && this.getItemMaxQty(item) < this.add_to_cart.quantity){
          window.$("#quantityid" + item.item_id).val(parseInt(this.getItemMaxQty(item)));
          this.is_min_qty = false;
          this.qty_alert_message = "The maximum quantity for item is " + this.getItemMaxQty(item) + "."
          window.$('#QuantityAlertModal').modal("show");
          return false;
        }
      }

      if(this.checkItemAddonChoice(item) != null && this.addon_choice_cart == false && this.add_to_cart.quantity != 0 && localStorage.getItem("user") != null){
        if(this.isItemAdded(item) == true && this.add_to_cart.add_new == 0){
          this.repeat_addon_choice = null;
          this.getRepeatAddonChoices(this.add_to_cart.store_id, this.add_to_cart.item_variants_id);
        } else {
          this.getItemAddonChoices(item, item.item_name);
        }
        return false;
      } else {
        this.addon_choice_cart = false;
      }

      if(item.is_combo == 1 && this.combo_cart == false && this.add_to_cart.quantity != 0 && localStorage.getItem("user") != null){
        var is_repeat = false;
        if(this.isItemAdded(item) == true && this.add_to_cart.add_new == 0){
          this.repeat_combo = null;
          is_repeat = true;
        } else {
          is_repeat = false;
        }
        this.getItemCombo(item, is_repeat);
        return false;
      } else {
        this.combo_cart = false;
      }

      if(localStorage.getItem("user") == null){
        this.addToLocalCart(item);
      } else {
        await this.$store
          .dispatch("addtocartproduct", this.add_to_cart)
          .then(response => {
            this.cart_response = JSON.parse(JSON.stringify(response));
            if (this.cart_response != null) {
              if (this.cart_response.status == "0") {
                this.cart_message = this.cart_response.message;
                window.$("#ReplaceCartModal").modal("show");
              } else {

                if (
                  this.getproductdetail.data.item_variants.find(
                    o => o.item_variants_id == this.item_variants.item_variants_id
                  ) != undefined
                ) {
                  let item_variant_index = item.item_variants.findIndex(
                      o => o.item_variants_id == this.item_variants.item_variants_id
                  );

                  if(this.add_to_cart.quantity != 0){
                    this.getproductdetail.data.item_variants[item_variant_index].is_added = 1;
                    this.getproductdetail.data.item_variants[item_variant_index].quantity = this.add_to_cart.quantity;
                  } else {
                    this.getproductdetail.data.item_variants[item_variant_index].is_added = 0;
                    this.getproductdetail.data.item_variants[item_variant_index].quantity = 1;
                  }

                }
                this.item_qty = 0;
                this.add_to_cart.add_new = 0;
                this.getCartCount();
                this.$toast.success(this.cart_response.message);
              }
            }
          });
      }
    },
    removeCart(item) {
      this.remove_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.remove_cart.cart_type = 1;
      
      if(localStorage.getItem("user") == null){
        this.removeLocalCart();
        if(this.is_min_qty == true){
          this.addMinQty(item);
          this.is_min_qty = false;
        }
        this.addToLocalCart(item);
        window.$("#ReplaceCartModal").modal("hide");
      } else {
        this.$store
          .dispatch("getemptycart", this.remove_cart)
          .then(response => {
            if (response.status == 1) {
              if(this.add_to_cart.item_id != ""){
                if(this.is_min_qty == true){
                  this.addMinQty(item);
                  this.is_min_qty = false;
                }
                this.addon_choice_cart = true;
                this.combo_cart = true;
                this.addToCart(item);
                window.$("#ReplaceCartModal").modal("hide");
              }
            }
          });
      }
    },
    openLoginfrm() {
      localStorage.setItem("LastUrlBeforeLogin",window.location.href);   
      window.$("#login-form").modal("show");
      window.$("#VerificationFields").removeClass("show");
      window.$("#SignupFields").removeClass("show");
      window.$("#ChangePassword").removeClass("show");
      window.$("#loginPopupFields").addClass("show");
    },
    productNotify(productid) {
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
        return false;
      }
      this.product_notify.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.product_notify.item_id = productid;
      this.$store
        .dispatch("getcustnotifyme", this.product_notify)
        .then(response => {
          if (response.status == 1) {
            window.$("#btnnotifyme" + this.product_notify.item_id).removeClass("btn btn-primary");
            window.$("#btnnotifyme" + this.product_notify.item_id).addClass("btn notifymegrayitem");
            this.getproductdetail.data.notify_me = 1;
            this.$toast.success("You will be notified once the item is in stock");
          } else {
            this.$toast.error(response.message);
          }
        });
    },
    checkItemAddonChoice(item){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          ).is_choice_addon;
      } else {
        return null;
      }
    },
    getChoiceAddon(item, value) {
      return item.item_variants.find(i => i.is_choice_addon == value) ? value : "null";
    },
    getItemAddonChoices(item, item_name) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
          var selected_item_variant = item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          );
          bus.$emit("ac_item", item);
          bus.$emit("ac_item_index", 0);
          bus.$emit("ac_item_variant", selected_item_variant);
          bus.$emit("ac_item_name", item_name);
          window.$("#AvailableOptionproductdetail").modal("show");
      } else {
        return false;
      }
    },
    isItemExistsUsedAddonChoices(local_used_addon_choice, used_addon_choice){
      const i = local_used_addon_choice.findIndex(o => o.item_id == used_addon_choice.item_id && o.item_variants_id == used_addon_choice.item_variants_id);
      if (i > -1) {
        local_used_addon_choice[i] = used_addon_choice;
      } else { 
        local_used_addon_choice.push(used_addon_choice);
      }
      return local_used_addon_choice;
    },
    addToCartAddonChoices(data){
      this.addon_choice_cart = true;
      this.add_to_cart.add_new = 1;
      this.add_to_cart.choices = JSON.stringify(data.choices);
      this.add_to_cart.addons = JSON.stringify(data.addons);

      // Used Addon Choice Local Storage
      this.used_addon_choice.item_id = data.item.item_id;
      this.used_addon_choice.item_name = data.item.item_name;
      this.used_addon_choice.brand_name = data.item.brand_name;
      this.used_addon_choice.item_variants_id = data.item_variant.item_variants_id;
      this.used_addon_choice.item_price = (parseFloat(data.item_variant.discounted_price) + parseFloat(data.addon_choice_price));
      this.used_addon_choice.choice_details = data.choices;
      this.used_addon_choice.addon_details = data.addons;

      var local_used_addon_choice = JSON.parse(localStorage.getItem("used_addon_choice")) || [];
      if(localStorage.getItem("used_addon_choice") != null) {
        var new_used_addon_choice = this.isItemExistsUsedAddonChoices(local_used_addon_choice, this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(new_used_addon_choice));
      } else {
        local_used_addon_choice.push(this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(local_used_addon_choice));
      }
      this.addToCart(data.item, data.index);

    },
    repeatItemAddonChoices(item){
      this.addon_choice_cart = true;
      this.add_to_cart.add_new = 0;
      this.addToCart(item);
      window.$('#RepeatAlertModal').modal("hide");
    },
    addNewItemAddonChoices(item){
      this.addon_choice_cart = false;
      this.add_to_cart.add_new = 1;
      this.addToCart(item);
      window.$('#RepeatAlertModal').modal("hide");
    },
    getRepeatAddonChoices(store_id, item_variants_id){
      this.used_choices_addons.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_choices_addons.store_id = store_id;
      this.used_choices_addons.item_variants_id = item_variants_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_choices_addons)
        .then(response => {
          if (response.status == 1) {
            this.repeat_addon_choice = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatAlertModal').modal("show");
          } else {
            this.repeat_addon_choice = null;
          }
        });
    },
    getItemCombo(item, is_repeat) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants.item_variants_id
        ) != undefined
      ) {
          var selected_item_variant = item.item_variants.find(
            o => o.item_variants_id == this.item_variants.item_variants_id
          );
          bus.$emit("comb_item", item);
          bus.$emit("comb_item_index", 0);
          bus.$emit("comb_item_variant", selected_item_variant);
          bus.$emit("comb_item_name", item.item_name);
          this.checkComboItem(item, 0, selected_item_variant, is_repeat);
      } else {
        return false;
      }
    },
    checkComboItem(item, index, selected_item_variant, is_repeat) {
       this.combo_item_data.item_id = item.item_id;
       this.combo_item_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
       this.$store
         .dispatch("getproductdetail", this.combo_item_data)
         .then(response => {
           this.combo_loading = false;
           var result = JSON.parse(JSON.stringify(response));
           if(result.status == 1 && result.data.combo_items != null){
             this.combo_item_details = result.data.combo_items;
             var vm = this;
             this.selectedComboItems = [];
             var has_addon_choices = false;
             this.combo_item_details.forEach(function(combo_items) {
              var filter_combo_items = combo_items.combo_variants.filter(sub => sub.addons != null || sub.choices != null);
              if(!has_addon_choices){
                has_addon_choices = (filter_combo_items.length > 0) ? true : false;
              }
              
              combo_items.combo_variants.forEach(function(citem) {
                var obj = {};
                obj['combo_item_id'] = combo_items.combo_item_id;
                obj['item_name'] = citem.item_name;
                obj['combo_item_detail_id'] = citem.combo_item_detail_id;
                obj['quantity'] = 1;
                vm.selectedComboItems.push(obj);
              });
             });
             result.data.combo_items.forEach(comboVariantObj => {
               if(comboVariantObj.combo_variants.length > 1)
               {
                 has_addon_choices = true;
               }
             })
             if(!has_addon_choices) {
               var selected_data = {};
               selected_data['item'] = item;
               selected_data['index'] = index;
               selected_data['item_variant'] = selected_item_variant;
               selected_data['combo_items'] = this.selectedComboItems;
               this.addToCartCombo(selected_data);
             } else {
              if(is_repeat){
                this.getRepeatCombo(this.add_to_cart.store_id, this.add_to_cart.item_variants_id);
              } else {
                window.$("#comboOfferproductdetail").modal("show");
              }
             }
           }
         });
    },
    isItemExistsUsedCombo(local_used_combo, used_combo){
      const i = local_used_combo.findIndex(o => o.item_id == used_combo.item_id && o.item_variants_id == used_combo.item_variants_id);
      if (i > -1) {
        local_used_combo[i] = used_combo;
      } else { 
        local_used_combo.push(used_combo);
      }
      return local_used_combo;
    },
    addToCartCombo(data){
      this.combo_cart = true;
      this.add_to_cart.add_new = 1;
      this.add_to_cart.combo_items = JSON.stringify(data.combo_items);
      
      // Used Addon Choice Local Storage
      this.used_combo.item_id = data.item.item_id;
      this.used_combo.item_name = data.item.item_name;
      this.used_combo.brand_name = data.item.brand_name;
      this.used_combo.item_variants_id = data.item_variant.item_variants_id;
      this.used_combo.item_price = (parseFloat(data.item_variant.discounted_price));
      this.used_combo.combo_items = data.combo_items;

      var local_used_combo = JSON.parse(localStorage.getItem("used_combo")) || [];
      if(localStorage.getItem("used_combo") != null) {
        var new_used_combo = this.isItemExistsUsedCombo(local_used_combo, this.used_combo);
        localStorage.setItem('used_combo', JSON.stringify(new_used_combo));
      } else {
        local_used_combo.push(this.used_combo);
        localStorage.setItem('used_combo', JSON.stringify(local_used_combo));
      }
      this.addToCart(data.item, data.index);

    },
    repeatItemCombo(item){
      this.combo_cart = true;
      this.add_to_cart.add_new = 0;
      this.addToCart(item);
      window.$('#RepeatComboAlertModal').modal("hide");
    },
    addNewItemCombo(item){
      this.combo_cart = false;
      this.add_to_cart.add_new = 1;
      this.addToCart(item);
      window.$('#RepeatComboAlertModal').modal("hide");
    },
    getRepeatCombo(store_id, item_variants_id){
      this.used_combo_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_combo_data.store_id = store_id;
      this.used_combo_data.item_variants_id = item_variants_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_combo_data)
        .then(response => {
          if (response.status == 1) {
            this.repeat_combo = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatComboAlertModal').modal("show");
          } else {
            this.repeat_combo = null;
          }
        });
    },
    closeAddNewCombo(data){
      if(data){
        this.add_to_cart.add_new = 0;
      }
    },
    filterComboAddons(addons){
      var tmp_addons = [];
      addons.forEach(addon => {
        addon.addon_prices.forEach(addon_price => {
          if(addon_price.is_added == 1){
            var obj = {};
            obj['variant_addon_name'] = addon.variant_addon_name;
            obj['variant_sub_addon_name'] = addon_price.variant_sub_addon_name;
            tmp_addons.push(obj);
          }
        });
      });
     return tmp_addons;
    },
    filterComboChoices(choices){
      var tmp_choices = [];
      choices.forEach(choice => {
        choice.choice_prices.forEach(choice_price => {
          if(choice_price.is_added == 1){
            var obj = {};
            obj['variant_choice_name'] = choice.variant_choice_name;
            obj['variant_sub_choice_name'] = choice_price.variant_sub_choice_name;
            tmp_choices.push(obj);
          }
        });
      });
     return tmp_choices;
    },
    fullReview(review){
      this.review_data = review;
      window.$('#CustomerReviewModal').modal('show');
    },
  }
};
</script>